import { Container } from "react-bootstrap";
import styles from "../../../assets/styles/ProjectPage.module.css"
import SectorsPageView from "./SectorsPageView";

const SectorsPage = () => {
    return (
        <Container className={styles.projectPage}>
            <>
                <SectorsPageView/>
            </>
        </Container>
    );
};

export default SectorsPage;
import { FormEvent, useEffect, useState } from "react";
import { Poll as PollModel } from "../../../services/models/poll";
import { useNavigate } from "react-router-dom";
import * as PollApi from "../../../services/network/poll-api";
import * as SubUserApi from "../../../services/network/sub_user-api";
import { Button, Card, Dropdown, DropdownButton, Form, Spinner } from "react-bootstrap";
import styles from "../../../assets/styles/CreateUpdatePoll.module.css";
import stylesUtils from "../../../assets/styles/utils.module.css"
import { SubUser } from "../../../services/models/sub_user";
import { FaRegCheckCircle, FaRegCircle,  } from "react-icons/fa";

interface UpdatePollPageViewProps {
    pollId: string,
}

const UpdatePollPageView = ({pollId}: UpdatePollPageViewProps) => {
    const [poll, setPoll] = useState<PollModel>(
        {
            id: "",
            tag:"",
            sector:"",
            allowed_sub_users:[],
            title: "",
            description: "",
            modules: [
                {
                    allowed_sub_users:[],
                    title: "",
                    description: "",
                    values: [
                        {
                            allowed_sub_users:[],
                            title: "",
                            value: 0.
                        },
                    ]
                },
            ],
        }
    );
    const [subUsers, setSubUsers] = useState<SubUser[]>([]);
    const [subUsersToShow, setSubUsersToShow] = useState<SubUser[]>([]);
    const [pollLoading, setPollLoading] = useState(true);
    const [showPollLoadingError, setShowPollLoadingError] = useState(false);

    const [subUsersNameFilter, setSubUsersNameFilter] = useState("");

    useEffect(() => {
        async function loadData() {
            try {
                setShowPollLoadingError(false);
                setPollLoading(true);
                const pollData = await PollApi.fetchPoll({pollId: pollId});
                const subUsersData = await SubUserApi.getSubUsers();
                setSubUsers(subUsersData);
                setSubUsersToShow(subUsersData);
                setPoll(pollData);
            } catch (error) {
                console.error(error);
                setShowPollLoadingError(true);
            } finally {
                setPollLoading(false);
            }
        }
        loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const filter = (e: any) => {
        const keyword = e.target.value;

        if (keyword !== "") {
            const filteredArray = subUsers.filter((user) => {
                return user.full_name.toLowerCase().startsWith(keyword.toLowerCase());
            })
            setSubUsersToShow(filteredArray);
        } else {
            setSubUsersToShow(subUsers);
        }

        setSubUsersNameFilter(keyword);
    }
    
    let navigate = useNavigate();
    
    const handleModuleChange = (index: number, field: keyof PollModel['modules'][0], value: string) => {
        const updatedModules = poll.modules;
        const allowedSubUsers = poll.allowed_sub_users;
        if (field === "allowed_sub_users") {
            if (!updatedModules[index].allowed_sub_users) {
                updatedModules[index].allowed_sub_users = [];
              }
              if (!updatedModules[index].allowed_sub_users.includes(value)) {
                updatedModules[index].allowed_sub_users.push(value);
                for (let valueIndex = 0; valueIndex < updatedModules[index].values.length; valueIndex++) {
                    if (!updatedModules[index].values[valueIndex].allowed_sub_users.includes(value)) {
                        updatedModules[index].values[valueIndex].allowed_sub_users.push(value);
                    }
                }
                if (!poll.allowed_sub_users.includes(value)) {
                    poll.allowed_sub_users.push(value);
                }
              } else {
                updatedModules[index].allowed_sub_users =
                updatedModules[index].allowed_sub_users.filter((id) => id !== value);
                for (let valueIndex = 0; valueIndex < updatedModules[index].values.length; valueIndex++) {
                    updatedModules[index].values[valueIndex].allowed_sub_users = 
                    updatedModules[index].values[valueIndex].allowed_sub_users.filter((id) => id !== value);
                }
                let inOtherModules = false;
                for (let i = 0; i < updatedModules.length; i++) {
                    if (updatedModules[i].allowed_sub_users.includes(value)) {
                        inOtherModules = true;
                        break;
                    }
                }
                if (!inOtherModules) {
                    poll.allowed_sub_users = allowedSubUsers.filter(
                        (id) => id !== value
                    );
                }
              }
        }
        setPoll({ ...poll, modules: updatedModules });
    };

    const handleValueChange = (moduleIndex: number, valueIndex: number , field: keyof PollModel['modules'][0]['values'][0], value: string|number) => {
        const updatedModules = poll.modules;
        if (field === "allowed_sub_users" && typeof value === "string") {
            if (!updatedModules[moduleIndex].values[valueIndex].allowed_sub_users) {
                updatedModules[moduleIndex].values[valueIndex].allowed_sub_users = [];
            }
            if (!updatedModules[moduleIndex].values[valueIndex].allowed_sub_users.includes(value)){
                updatedModules[moduleIndex].values[valueIndex].allowed_sub_users.push(value);
                if(!updatedModules[moduleIndex].allowed_sub_users.includes(value)){
                    updatedModules[moduleIndex].allowed_sub_users.push(value);
                }
                if (!poll.allowed_sub_users.includes(value)) {
                    poll.allowed_sub_users.push(value);
                }
            }
            else {
                updatedModules[moduleIndex].values[valueIndex].allowed_sub_users = 
                updatedModules[moduleIndex].values[valueIndex].allowed_sub_users.filter((id) => id !== value)
                let inOtherValues = false;
                for (let i = 0; i < updatedModules[moduleIndex].values.length; i++){
                    if (updatedModules[moduleIndex].values[i].allowed_sub_users.includes(value)){
                        inOtherValues = true;
                        break;
                    }
                }
                if (!inOtherValues) {
                    updatedModules[moduleIndex].allowed_sub_users = updatedModules[moduleIndex].allowed_sub_users.filter(
                        (id) => id !== value
                    );
                }
                let inOtherModules = false;
                for (let i = 0; i < updatedModules.length; i++) {
                    if (updatedModules[i].allowed_sub_users.includes(value)) {
                        inOtherModules = true;
                        break;
                    }
                }
                if (!inOtherModules) {
                    poll.allowed_sub_users = poll.allowed_sub_users.filter(
                        (id) => id !== value
                    );
                }
            }
        }
        setPoll({ ...poll, modules: updatedModules });
    };

    async function handleSubmit(event: FormEvent) {
        event.preventDefault()
        try {
            let pollResponse: PollModel;
            pollResponse = await PollApi.updatePoll(pollId, poll);
            setPoll(pollResponse);
        } catch (error) {
            console.error(error);
            alert(error);
        } finally {
            navigate(-1);
        }
    }
    
    return (
        <>
            <div className={`${stylesUtils.width100} ${stylesUtils.flexLeft}`}>
              <Button
                    className={`mb-1`}
                    onClick={() => navigate(-1)}
                    >
                    Назад
                </Button>
            </div>
            {pollLoading && <Spinner animation='border' variant='primary' />}
            {showPollLoadingError && <p>Something went wrong. Please refresh the page.</p>}
            {!pollLoading && !showPollLoadingError && 
                <Form onSubmit={handleSubmit}>
                    <h1>Редактирование опроса</h1>
                    {poll.modules.map((module, moduleIndex) => (
                        <Card key={moduleIndex} className="mb-3">
                            <Card.Header>Модуль {moduleIndex + 1}</Card.Header>
                            <Card.Body>
                                <Form.Group className="mb-2" controlId={`module-allowed_sub_users-${moduleIndex}`}>
                                    <Form.Label>Разрешённые пользователи</Form.Label>
                                    <DropdownButton
                                        id={`allowed-users-dropdown-${moduleIndex}`}
                                        title="Выбрать пользователей"
                                        autoClose="outside"
                                    >
                                        <input
                                            type="search"
                                            value={subUsersNameFilter}
                                            onChange={filter}
                                            className={styles.search}
                                            placeholder="Поиск по ФИО">
                                        </input>
                                        {subUsersToShow.map((subUser) => (
                                        <Dropdown.Item
                                            key={subUser.id}
                                            onClick={() => handleModuleChange(moduleIndex, 'allowed_sub_users', subUser.id)}
                                            className={styles.subUserItem}
                                            >
                                            {subUser.full_name}
                                            {module.allowed_sub_users?.includes(subUser.id) 
                                            ?  <FaRegCheckCircle/>
                                            : <FaRegCircle/>
                                            }
                                        </Dropdown.Item>
                                        ))}
                                    </DropdownButton>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId={`module-values-${moduleIndex}`}>
                                    <h3>Значения</h3>
                                    {module.values.map((value, valueIndex) => (
                                        <div key={valueIndex}>
                                            <Form.Group className={`mb-3`} controlId={`value-name-${moduleIndex}-${valueIndex}`}>
                                                <Form.Label>Название</Form.Label>
                                                <div className={styles.value}>    
                                                    <Form.Control
                                                        type="text"
                                                        value={value.title}
                                                        readOnly={true}
                                                    />
                                                    <DropdownButton
                                                        id={`allowed-users-dropdown-${moduleIndex}-${valueIndex}`}
                                                        title="Доступы"
                                                        autoClose="outside"
                                                        className={`ms-2`}
                                                    >
                                                        <input
                                                            type="search"
                                                            value={subUsersNameFilter}
                                                            onChange={filter}
                                                            className={styles.search}
                                                            placeholder="Поиск по ФИО">
                                                        </input>
                                                        {subUsersToShow.map((subUser) => (
                                                        <Dropdown.Item
                                                            key={subUser.id}
                                                            onClick={() => handleValueChange(moduleIndex, valueIndex, 'allowed_sub_users', subUser.id)}
                                                            className={styles.subUserItem}
                                                            >
                                                            {subUser.full_name}
                                                            {value.allowed_sub_users?.includes(subUser.id) 
                                                            ?  <FaRegCheckCircle/>
                                                            : <FaRegCircle/>
                                                            }
                                                        </Dropdown.Item>
                                                        ))}
                                                    </DropdownButton>
                                                </div>
                                            </Form.Group>
                                        </div>
                                    ))}
                                </Form.Group>
                            </Card.Body>
                        </Card>
                    ))}
                    <div className={stylesUtils.flexCenter}>
                        <Button type="submit">Сохранить</Button>
                    </div>
                </Form>
            }
        </>
    );
}

export default UpdatePollPageView;
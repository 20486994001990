import { Button, Container } from "react-bootstrap";
import styles from "../../../assets/styles/AuthPage.module.css";
import stylesUtils from "../../../assets/styles/utils.module.css";
import { Link } from "react-router-dom";

const AdministratorCabinetPage = () => {
    return (
        <Container className={styles.authPage}>
            <Link to="poll_templates">
                <Button className={`mb-4 ${stylesUtils.flexSizeButton} ${stylesUtils.flexCenter}` }>
                    Опросы
                </Button>
            </Link>
            <Link to="statistics">
                <Button className={`mb-4 ${stylesUtils.flexSizeButton} ${stylesUtils.flexCenter}` }>
                    Статистика
                </Button>
            </Link>
            <Link to="sectors">
                <Button className={`mb-4 ${stylesUtils.flexSizeButton} ${stylesUtils.flexCenter}` }>
                    Сектора
                </Button>
            </Link>
            <Link to="roles">
                <Button className={`mb-4 ${stylesUtils.flexSizeButton} ${stylesUtils.flexCenter}` }>
                    Роли
                </Button>
            </Link>
            <Link to="sub_users">
                <Button className={`mb-4 ${stylesUtils.flexSizeButton} ${stylesUtils.flexCenter}` }>
                    Пользователи
                </Button>
            </Link>
        </Container>
    )
}

export default AdministratorCabinetPage;
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { SubUser } from "../../../services/models/sub_user";
import { useForm } from "react-hook-form";
import * as SubUserApi from "../../../services/network/sub_user-api";
import * as SectorApi from "../../../services/network/sector-api";
import TextInputField from "../../../components/AdminComponents/form/TextInputField";
import SelectInputField from "../../../components/AdminComponents/form/SelectInputField";
import { Role } from "../../../services/models/role";
import { useEffect, useState } from "react";
import * as RoleApi from "../../../services/network/role-api";
import { Sector } from "../../../services/models/sector";

interface AddEditSubUserDialogProps {
    subUserToEdit?: SubUser,
    onDismiss: () => void,
    onSubUserSaved: (subUser: SubUser) => void,
}

const AddEditSubUserDialog = ({subUserToEdit, onDismiss, onSubUserSaved}: AddEditSubUserDialogProps) => {
    const [roles, setRoles] = useState<Role[]>([]);
    const [sectors, setSectors] = useState<Sector[]>([]);
    const [dataLoading, setDataLoading] = useState(true);
    const [showRolesLoadingError, setShowRolesLoadingError] = useState(false);

    useEffect(() => {
        async function loadRoles() {
            try {
                setShowRolesLoadingError(false);
                setDataLoading(true);
                const rolesData = await RoleApi.fetchRoles();
                const sectorsData = await SectorApi.fetchSectors();
                setSectors(sectorsData);
                setRoles(rolesData);
            } catch (error) {
                console.error(error);
                setShowRolesLoadingError(true);
            } finally {
                setDataLoading(false);
            }
        }
        loadRoles();
    }, []);

    interface SubUserFormInput{
        role: string,
        sector: string,
        full_name: string,
        email: string,
        password: string
    }

    const { register, handleSubmit, formState: {errors, isSubmitting} } = useForm<SubUserFormInput>({
        defaultValues: {
            role: subUserToEdit?.role || "",
            sector: subUserToEdit?.sector || "",
            full_name: subUserToEdit?.full_name || "",
            email: subUserToEdit?.email || "",
            password: "",
        }
    });

    async function onSubmit(input: SubUserFormInput) {
        try {
            let subUserResponse: SubUser;
            if (subUserToEdit) {
                subUserResponse = await SubUserApi.updateSubUser(subUserToEdit.id, input);
            } else {
                subUserResponse = await SubUserApi.createSubUser(input);
            }
            onSubUserSaved(subUserResponse);
        } catch (error) {
            console.error(error);
            alert(error);
        }
    }
    
    return(
        <Modal show onHide={onDismiss}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {subUserToEdit ? "Редактировать пользователя" : "Добавить пользователя"}
                </Modal.Title>
            </Modal.Header>
            {dataLoading && <Spinner animation='border' variant='primary' />}
            {showRolesLoadingError && <p>Something went wrong. Please refresh the page.</p>}
            {!dataLoading && !showRolesLoadingError && 
                <Modal.Body>
                    <Form id="addEditSubUserForm" onSubmit={handleSubmit(onSubmit)}>
                        <SelectInputField
                            name="role"
                            label="Роль"
                            items={roles}
                            register={register}
                            registerOptions={{required: "Required"}}
                            error={errors.role}
                        />
                        <SelectInputField
                            name="sector"
                            label="Сектор"
                            items={sectors}
                            register={register}
                            registerOptions={{required: "Required"}}
                            error={errors.sector}
                        />
                        <TextInputField
                            name="full_name"
                            label="ФИО"
                            type="text"
                            placeholder="ФИО"
                            register={register}
                            registerOptions={{required: "Required"}}
                            error={errors.full_name}
                        />
                        <TextInputField
                            name="email"
                            label="Почта"
                            type="text"
                            placeholder="mail@example.ru"
                            register={register}
                            registerOptions={{required: "Required"}}
                            error={errors.email}
                        />
                        {subUserToEdit
                        ? <TextInputField
                            name="password"
                            label="Пароль"
                            type="text"
                            placeholder="Пароль"
                            register={register}
                            error={errors.password}
                        />
                        :<TextInputField
                            name="password"
                            label="Пароль"
                            type="text"
                            placeholder="Пароль"
                            register={register}
                            registerOptions={{required: "Required"}}
                            error={errors.password}
                        />
                        }
                        
                    </Form>
                </Modal.Body>
            }
            <Modal.Footer>
                <Button 
                    type="submit"
                    form="addEditSubUserForm"
                    disabled={isSubmitting}
                    >
                    Сохранить
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default AddEditSubUserDialog;
import { LoginCredentials } from "../models/credentials";
import { SubUser, LoginSubUser } from "../models/sub_user";
import { fetchData } from "../../Utils/fetchData";
import authToken from "../../Utils/authToken"


// Part for administrator
export async function getSubUsers(): Promise<SubUser[]> {
    const response = await fetchData("https://nmic.testu.online/api/sub_user",
        {
            method: "GET",
            headers: {
                "Authorization": authToken(),
            }
        }
    )
    return response.json()
}

export async function getSubUser(subUserId: string): Promise<SubUser> {
    const response = await fetchData("https://nmic.testu.online/api/sub_user"+subUserId,
        {
            method: "GET",
            headers: {
                "Authorization": authToken(),
            }
        }
    )
    return response.json()
}

export interface SubUserInput {
    role: string,
    sector: string,
    full_name: string,
    email: string,
    password: string,
}

export async function createSubUser(sub_user: SubUserInput): Promise<SubUser> {
    const response = await fetchData("https://nmic.testu.online/api/sub_user",
    {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "Authorization": authToken(),
        },
        body: JSON.stringify(sub_user),
    });
    return response.json();
}

export async function updateSubUser(subUserId: string, subUser: SubUserInput): Promise<SubUser> {
    const response = await fetchData("https://nmic.testu.online/api/sub_user/"+subUserId,
        {
            method: "POST",
            headers: {
                "Content-type": "application/json",
                "Authorization": authToken(),
            },
            body: JSON.stringify(subUser),
        })
        return response.json();
}

export async function deleteSubUser(subUserId: string) {
    await fetchData("https://nmic.testu.online/api/sub_user/"+subUserId, 
        { 
            method: "DELETE",
            headers: {
                "Authorization": authToken(),
            },
        });
}

// Part for sub user
export async function getLoggedSubUser(): Promise<SubUser> {
    const response = await fetchData("https://nmic.testu.online/api/sub_user/me", 
        { 
            method: "GET",
            headers:{
                "Authorization": authToken()
            }
        });
    return response.json();
}

export async function loginSubUser(credentials: LoginCredentials): Promise<LoginSubUser> {
    const response = await fetchData("https://nmic.testu.online/api/sub_user/login",
    {
        method: "POST",
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams({
            'username': credentials.login,
            'password': credentials.password,
        })
    });
    return response.json();
}

export async function logout() {
    // await fetchData("/api/administrators/logout", {method: "POST"});
    localStorage.removeItem("user")
}

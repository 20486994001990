import { Button, Card } from "react-bootstrap";
import { MdDelete, MdContentCopy, MdEdit } from "react-icons/md";
import { Poll as PollModel } from "../services/models/poll";
import { Sector as SectorModel } from "../services/models/sector";
import styles from "../assets/styles/Project.module.css";
import styleUtils from "../assets/styles/utils.module.css";

interface PollProps {
    poll: PollModel,
    sector?: SectorModel,
    onEditPollClicked: (poll: PollModel) => void,
    onDeletePollClicked: (poll: PollModel) => void,
    onPollResultClicked: (poll: PollModel) => void,
    onFillPollClicked: (poll: PollModel) => void,
    className?: string,
};

const Poll = ({ poll, sector, onEditPollClicked, onDeletePollClicked, onPollResultClicked, onFillPollClicked, className }: PollProps) => {
    const {
        title,
    } = poll;

    return (
        <Card
            className={`${styles.projectCard} ${className}`}
            >
            <Card.Body className={styles.cardBody}>
                <Card.Title className={styleUtils.flexCenterSpaceBetween}>
                    {title + " - " + sector?.name}
                    <div>
                        <MdEdit
                            className="ms-2 text-muted ms-rights"
                            onClick={(e) => {
                                onEditPollClicked(poll);
                                e.stopPropagation();
                            }}
                        />
                        <MdDelete
                            className="ms-2 text-muted ms-right"
                            onClick={(e) => {
                                onDeletePollClicked(poll);
                                e.stopPropagation();
                            }}
                        />
                    </div>
                </Card.Title>
            </Card.Body>
            <Card.Footer className={styles.pollButtons}>
                <Button 
                onClick={(e) => {
                    onPollResultClicked(poll);
                    e.stopPropagation();
                    }}>
                    Результаты
                </Button>
                <Button onClick={(e) => {
                    onFillPollClicked(poll);
                    e.stopPropagation();
                    }}>
                        Заполнить
                </Button>
            </Card.Footer>
        </Card>
    );
};

export default Poll;
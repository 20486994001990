import { Button, Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import TextInputField from "../../../components/AdminComponents/form/TextInputField";
import { Sector } from "../../../services/models/sector";
import * as SectorApi from "../../../services/network/sector-api";

interface AddEditSectorDialogProps {
    sectorToEdit?: Sector,
    onDismiss: () => void,
    onSectorSaved: (Sector: Sector) => void,
}

const AddEditSectorDialog = ({sectorToEdit, onDismiss, onSectorSaved}: AddEditSectorDialogProps) => {
    interface SectorFormInput{
        name: string,
        poll_template: string
    }

    const { register, handleSubmit, formState: {errors, isSubmitting} } = useForm<SectorFormInput>({
        defaultValues: {
            name: sectorToEdit?.name || "",
        }
    });

    async function onSubmit(input: SectorFormInput) {
        try {
            let sectorResponse: Sector;
            if (sectorToEdit) {
                sectorResponse = await SectorApi.updateSector(sectorToEdit.id, input);
            } else {
                sectorResponse = await SectorApi.createSector(input);
            }
            onSectorSaved(sectorResponse);
        } catch (error) {
            console.error(error);
            alert(error);
        }
    }
    
    return(
        <Modal show onHide={onDismiss}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {sectorToEdit ? "Редактировать сектор" : "Добавить сектор"}
                </Modal.Title>
            </Modal.Header> 
            <Modal.Body>
                <Form id="addEditSectorForm" onSubmit={handleSubmit(onSubmit)}>
                    <TextInputField
                        name="name"
                        label="Название"
                        type="text"
                        placeholder="Сектор"
                        register={register}
                        registerOptions={{required: "Required"}}
                        error={errors.name}
                    />
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button 
                    type="submit"
                    form="addEditSectorForm"
                    disabled={isSubmitting}
                    >
                    Сохранить
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default AddEditSectorDialog;
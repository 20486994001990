import { useEffect, useState } from "react";
import { PollTemplate as PollTemplateModel } from "../../../services/models/poll_template";
import { useNavigate } from "react-router-dom";
import * as PollTemplateApi from "../../../services/network/poll_template-api";
import * as RoleApi from "../../../services/network/role-api";
import { Button, Card, Dropdown, DropdownButton, Form, Spinner } from "react-bootstrap";
import styles from "../../../assets/styles/CreateUpdatePoll.module.css";
import stylesUtils from "../../../assets/styles/utils.module.css"
import { Role } from "../../../services/models/role";

interface PollTemplatePageViewProps {
    pollTemplateId: string,
}

const PollTemplatePageView = ({pollTemplateId}: PollTemplatePageViewProps) => {
    const [pollTemplate, setPollTemplate] = useState<PollTemplateModel>({
        id: "",
        allowed_roles: [],
        title: "",
        description: "",
        modules: [
            {
                allowed_roles:[],
                title: "",
                description: "",
                values: [
                    {
                        allowed_roles: [],
                        title: "",
                        value: 0.
                    },
                ]
            },
        ],
    });
    const [roles, setRoles] = useState<Role[]>([]);
    const [pollTemplateLoading, setPollTemplateLoading] = useState(true);
    const [showPollTemplateLoadingError, setShowPollTemplateLoadingError] = useState(false);

    useEffect(() => {
        async function loadPollTemplate() {
                try {
                    setShowPollTemplateLoadingError(false);
                    setPollTemplateLoading(true);
                    const pollTemplateData = await PollTemplateApi.fetchPollTemplate({pollTemplateId: pollTemplateId});
                    const rolesData = await RoleApi.fetchRoles();
                    setRoles(rolesData);
                    setPollTemplate(pollTemplateData);
                } catch (error) {
                    console.error(error);
                    setShowPollTemplateLoadingError(true);
                } finally {
                    setPollTemplateLoading(false);
                }
        }
        loadPollTemplate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    let navigate = useNavigate();
    
    return (
        <>
            <div className={`${stylesUtils.width100} ${stylesUtils.flexLeft}`}>
              <Button
                    className={`mb-1`}
                    onClick={() => navigate(-1)}
                    >
                    Назад
                </Button>
            </div>
            {pollTemplateLoading && <Spinner animation='border' variant='primary' />}
            {showPollTemplateLoadingError && <p>Something went wrong. Please refresh the page.</p>}
            {!pollTemplateLoading && !showPollTemplateLoadingError && 
                <>
                    <h1>Просмотр шаблона</h1>
                    <Form.Group className="mb-2" controlId={`poll-title`}>
                        <Form.Label>Название</Form.Label>
                        <Form.Control
                            type="text"
                            value={pollTemplate.title}
                            readOnly
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId={`poll-description`}>
                        <Form.Label>Описание</Form.Label>
                        <Form.Control
                            type="textarea"
                            value={pollTemplate.description}
                            readOnly
                        />
                    </Form.Group>
                    {pollTemplate.modules.map((module, moduleIndex) => (
                        <Card key={moduleIndex} className="mb-3">
                            <Card.Header>Модуль {moduleIndex + 1}</Card.Header>
                            <Card.Body>
                                <Form.Group className="mb-2" controlId={`module-roles-${moduleIndex}`}>
                                    <Form.Label>Разрешённые роли</Form.Label>
                                    <DropdownButton
                                        id={`allowed-roles-dropdown-${moduleIndex}`}
                                        title="Выбрать роли"
                                        autoClose="outside"
                                    >
                                        {module.allowed_roles.map((role) => (
                                        <Dropdown.Item
                                            key={role}
                                            className={styles.subUserItem}
                                            >
                                            {roles.find(roleObject => {return roleObject.id === role})?.name}
                                        </Dropdown.Item>
                                        ))}
                                    </DropdownButton>
                                </Form.Group>
                                <Form.Group className="mb-2" controlId={`module-name-${moduleIndex}`}>
                                    <Form.Label>Название</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={module.title}
                                        readOnly
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId={`module-description-${moduleIndex}`}>
                                    <Form.Label>Описание</Form.Label>
                                    <Form.Control
                                        type="textarea"
                                        value={module.description}
                                        readOnly
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId={`module-values-${moduleIndex}`}>
                                    <h3>Значения</h3>
                                    {module.values.map((value, valueIndex) => (
                                        <div key={valueIndex}>
                                            <Form.Group className={`mb-3`} controlId={`value-name-${moduleIndex}-${valueIndex}`}>
                                                <Form.Label>Название</Form.Label>
                                                <div className={styles.value}>    
                                                    <Form.Control
                                                        type="text"
                                                        value={value.title}
                                                        readOnly
                                                    />
                                                    <DropdownButton
                                                        id={`allowed-roles-dropdown-${moduleIndex}-${valueIndex}`}
                                                        title="Роли"
                                                        autoClose="outside"
                                                        className={`ms-2`}
                                                    >
                                                        {value.allowed_roles.map((role) => (
                                                        <Dropdown.Item
                                                            key={role}
                                                            className={styles.subUserItem}
                                                            >
                                                            {roles.find(roleObject => {return roleObject.id === role})?.name}
                                                        </Dropdown.Item>
                                                        ))}
                                                    </DropdownButton>
                                                </div>
                                            </Form.Group>
                                        </div>
                                    ))}
                                </Form.Group>
                            </Card.Body>
                        </Card>
                    ))}
                    </>
            }
        </>
    );
}

export default PollTemplatePageView;
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import styles from "../../../assets/styles/ProjectsPage.module.css";
import stylesUtils from "../../../assets/styles/utils.module.css";
import { PollTag as PollTagModel } from "../../../services/models/poll_tag";
import * as PollTagApi from "../../../services/network/poll_tag-api";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PollTag from "../../../components/PollTag";
import * as PollTemplateApi from "../../../services/network/poll_template-api";
import { PollTemplate as PollTemplateModel } from "../../../services/models/poll_template";

interface PollTagsPageViewProps {
    pollTemplateId: string,
}

const PollTagsPageView = ({pollTemplateId}: PollTagsPageViewProps) => {
    const [pollTemplate, setPollTemplate] = useState<PollTemplateModel|null>(null);
    const [pollTags, setPollTags] = useState<PollTagModel[]>([]);
    const [pollTagsLoading, setPollTagsLoading] = useState(true);
    const [showPollTagsLoadingError, setShowPollTagsLoadingError] = useState(false);

    useEffect(() => {
        async function loadPollTags() {
            try {
                setShowPollTagsLoadingError(false);
                setPollTagsLoading(true);
                const pollTemplateData = await PollTemplateApi.fetchPollTemplate({pollTemplateId});
                const pollTagsData = await PollTagApi.fetchPollTags({poll_template_id: pollTemplateId});
                setPollTemplate(pollTemplateData);
                setPollTags(pollTagsData);
            } catch (error) {
                console.error(error);
                setShowPollTagsLoadingError(true);
            } finally {
                setPollTagsLoading(false);
            }
        };
        loadPollTags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function deletePollTag(pollTag: PollTagModel) {
        try {
          await PollTagApi.deletePollTag(pollTag.id)
          setPollTags(pollTags.filter(existingPollTag => existingPollTag.id !== pollTag.id));
        } catch (error) {
          console.error(error);
          alert(error);
        }
      }

    let navigate = useNavigate();

    const goToPollTagSectors = (pollTag: PollTagModel) => {
        let path = `${pollTag.id}`;
        navigate(path);
    }

    const goToPollTagCreate = () => {
        let path = `create`;
        navigate(path);
    }

    const goToPollTagEdit = (pollTag: PollTagModel) => {
        let path = `${pollTag.id}/update`;
        navigate(path);
    }

    const pollTemplatesGrid =
        <Row xs={1} md={1} xl={1} className={`g-4 ${styles.projectsGrid}`}>
            {pollTags.map(pollTag => (
                <Col key={pollTag.id}>
                    {pollTemplate &&
                        <PollTag
                            pollTag={pollTag}
                            pollTemplate={pollTemplate}
                            onPollTagClicked={goToPollTagSectors}
                            onEditPollTagClicked={goToPollTagEdit}
                            onDeletePollTagClicked={deletePollTag}
                            className={styles.project}
                        />
                    }
                </Col>
            ))}
        </Row>
    return(
        <>
            <div className={`${stylesUtils.width100} ${stylesUtils.flexLeft}`}>
                <Button
                    className={`mb-1`}
                    onClick={() => navigate(-1)}
                    >
                    Назад
                </Button>
            </div>
            <h1>Группа</h1>
            <Button
                className={`mb-4  ${stylesUtils.flexCenter}`}
                onClick={goToPollTagCreate}>
                <FaPlus />
                Добавить группу
            </Button>
            {pollTagsLoading && <Spinner animation='border' variant='primary' />}
            {showPollTagsLoadingError && <p>Something went wrong. Please refresh the page.</p>}
            {!pollTagsLoading && !showPollTagsLoadingError && 
                <>
                {
                    pollTags.length > 0
                    ? pollTemplatesGrid
                    : <p>Групы ещё не добавлены</p>
                }
                </>
            }
        </>
    );
};

export default PollTagsPageView;
import { Container } from "react-bootstrap";
import styles from "../../../assets/styles/ProjectPage.module.css"
import PollTemplatePageView from "./PollTemplatePageView";
import { useParams } from "react-router-dom";
import NotFoundPage from "../../NotFound/NotFoundPage";

const PollTemplatePage = () => {
    const {pollTemplateId} = useParams();
    return (
        <Container className={styles.projectPage}>
            <>
                {pollTemplateId ?
                    <PollTemplatePageView pollTemplateId={pollTemplateId}/> :
                    <NotFoundPage/>
                }
            </>
        </Container>
    );
};

export default PollTemplatePage;
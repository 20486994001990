import { PollTemplate } from "../services/models/poll_template";
import { SubUser } from "../services/models/sub_user";
import * as PollApi from "../services/network/poll-api";

interface createPollsProps {
    sectorIdsToUse: string[], 
    pollTemplate: PollTemplate, 
    subUsers: SubUser[],
    pollTagId: string,
}

export default function createPolls({sectorIdsToUse, pollTemplate, subUsers, pollTagId}: createPollsProps) {
    sectorIdsToUse.forEach(async sectorId => {
        let poll: PollApi.PollInput = {
            sector: sectorId,
            tag: pollTagId,
            allowed_sub_users: [],
            title: pollTemplate.title,
            description: pollTemplate.description,
            modules:[]
        };
        for (let moduleIndex = 0; moduleIndex < pollTemplate.modules.length; moduleIndex++) {
            const module = pollTemplate.modules[moduleIndex];
            poll.modules.push({
                allowed_sub_users: [],
                title: module.title,
                description: module.description,
                values:[]
            });
            for (let valueIndex = 0; valueIndex < module.values.length; valueIndex++) {
                const value = module.values[valueIndex];
                const allowedSubUsers = subUsers.filter(subUser => {return subUser.sector === sectorId &&  value.allowed_roles.includes(subUser.role)}).map(subUser => subUser.id);
                poll.modules[moduleIndex].values.push({
                    allowed_sub_users: allowedSubUsers,
                    title: value.title,
                    value: 0
                });
                for (let subUserIndex = 0; subUserIndex < allowedSubUsers.length; subUserIndex++) {
                    if (!(allowedSubUsers[subUserIndex] in poll.modules[moduleIndex].allowed_sub_users)) {
                        poll.modules[moduleIndex].allowed_sub_users.push(allowedSubUsers[subUserIndex]);
                    };
                    if (!(allowedSubUsers[subUserIndex] in poll.allowed_sub_users)) {
                        poll.allowed_sub_users.push(allowedSubUsers[subUserIndex]);
                    };
                }
            }
        }
        await PollApi.createPoll(poll);
    });
};
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import styles from "../../../assets/styles/ProjectsPage.module.css";
import stylesUtils from "../../../assets/styles/utils.module.css";
import { PollTemplate as PollTemplateModel } from "../../../services/models/poll_template";
import * as PollTemplateApi from "../../../services/network/poll_template-api"
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PollTemplate from "../../../components/PollTemplate";

const PollTemplatesPageView = () => {
    const [pollTemplates, setPollTemplates] = useState<PollTemplateModel[]>([]);
    const [pollTemplatesLoading, setPollTemplatesLoading] = useState(true);
    const [showPollTemplatesLoadingError, setShowPollTemplatesLoadingError] = useState(false);

    useEffect(() => {
        async function loadPollTemplates() {
            try {
                setShowPollTemplatesLoadingError(false);
                setPollTemplatesLoading(true);
                const pollsData = await PollTemplateApi.fetchPollTemplates();
                setPollTemplates(pollsData);
            } catch (error) {
                console.error(error);
                setShowPollTemplatesLoadingError(true);
            } finally {
                setPollTemplatesLoading(false);
            }
        };
        loadPollTemplates();
    }, []);

    async function deletePollTemplate(poll:PollTemplateModel) {
        try {
          await PollTemplateApi.deletePollTemplate(poll.id)
          setPollTemplates(pollTemplates.filter(existingPoll => existingPoll.id !== poll.id));
        } catch (error) {
          console.error(error);
          alert(error);
        }
      }

    let navigate = useNavigate();

    const goToPollTemplateSectors = (poll: PollTemplateModel) => {
        let path = `${poll.id}`;
        navigate(path);
    }
    const goToPollTemplateView = (poll: PollTemplateModel) => {
        let path = `${poll.id}/view`;
        navigate(path);
    }

    const goToPollTemplateCreate = () => {
        let path = `create`;
        navigate(path);
    }

    const goToPollTemplateCopy = (poll: PollTemplateModel) => {
        let path = `${poll.id}/copy`;
        navigate(path);
    }

    const pollTemplatesGrid =
        <Row xs={1} md={1} xl={1} className={`g-4 ${styles.projectsGrid}`}>
            {pollTemplates.map(pollTemplate => (
                <Col key={pollTemplate.id}>
                    <PollTemplate
                        pollTemplate={pollTemplate}
                        onViewPollTemplateClicked={goToPollTemplateView}
                        onCopyPollTemplateClicked={goToPollTemplateCopy}
                        onPollTemplateClicked={goToPollTemplateSectors}
                        onDeletePollTemplateClicked={deletePollTemplate}
                        className={styles.project}
                    />
                </Col>
            ))}
        </Row>
    return(
        <>
            <div className={`${stylesUtils.width100} ${stylesUtils.flexLeft}`}>
                <Button
                    className={`mb-1`}
                    onClick={() => navigate(-1)}
                    >
                    Назад
                </Button>
            </div>
            <h1>Шаблоны</h1>
            <Button
                className={`mb-4  ${stylesUtils.flexCenter}`}
                onClick={goToPollTemplateCreate}>
                <FaPlus />
                Добавить шаблон
            </Button>
            {pollTemplatesLoading && <Spinner animation='border' variant='primary' />}
            {showPollTemplatesLoadingError && <p>Something went wrong. Please refresh the page.</p>}
            {!pollTemplatesLoading && !showPollTemplatesLoadingError && 
                <>
                {
                    pollTemplates.length > 0
                    ? pollTemplatesGrid
                    : <p>Шаблоны ещё не добавлены</p>
                }
                </>
            }
        </>
    );
};

export default PollTemplatesPageView;
import { Card } from "react-bootstrap";
import { Poll as PollModel } from "../services/models/poll";
import styles from "../assets/styles/Project.module.css";
import styleUtils from "../assets/styles/utils.module.css";

interface PollForSubUserProps {
    poll: PollModel,
    sectorName: string,
    tagName: string,
    onFillPollClicked: (poll: PollModel) => void,
    className?: string,
};

const PollForSubUser = ({ poll, sectorName, tagName, onFillPollClicked, className }: PollForSubUserProps) => {
    const {
        title
    } = poll;

    return (
        <Card
            className={`${styles.projectCard} ${className}`}
            onClick={() => onFillPollClicked(poll)}
            >
            <Card.Body className={styles.cardBody}>
                <Card.Title className={styleUtils.flexCenterSpaceBetween}>
                    {title + " - " + sectorName + " - " + tagName}
                </Card.Title>
            </Card.Body>
        </Card>
    );
};

export default PollForSubUser;
import { Poll } from "../models/poll";
import { fetchData } from "../../Utils/fetchData";
import authToken from "../../Utils/authToken";

interface fetchPollsProps {
    tag_id: string,
}

export async function fetchPolls(pollsRequest: fetchPollsProps) : Promise<Poll[]>{

    const response = await fetchData("https://nmic.testu.online/api/poll",
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": authToken(),
            },
            body: JSON.stringify(pollsRequest),
        });
    return response.json();
}

export async function fetchPollsForSubUser() : Promise<Poll[]>{

    const response = await fetchData("https://nmic.testu.online/api/poll",
        {
            method: "GET",
            headers: {
                "Authorization": authToken(),
            },
        });
    return response.json();
}

interface fetchPollProps {
    pollId: string,
}

export async function fetchPoll(requestProps: fetchPollProps): Promise<Poll> {
    const response = await fetchData("https://nmic.testu.online/api/poll/"+requestProps.pollId, 
    {
        method: "GET",
        headers: {
            "Authorization": authToken(),
        },
    });
    return response.json();
}

export interface PollInput{
    sector: string,
    tag: string,
    allowed_sub_users: string[],
    title: string,
    description: string,
    modules:
            {
                allowed_sub_users:string[],
                title: string,
                description: string,
                values:
                    {
                        allowed_sub_users: string[],
                        title: string,
                        value: number,
                    }[]
            }[]
}

export async function createPoll(poll: PollInput): Promise<Poll> {
    const response = await fetchData("https://nmic.testu.online/api/poll",
    {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "Authorization": authToken(),
        },
        body: JSON.stringify(poll),
    });
    return response.json();
}

export async function updatePoll(pollId:string, poll: PollInput): Promise<Poll> {
    const response = await fetchData("https://nmic.testu.online/api/poll/" + pollId,
    {
        method: "POST",
        headers: {
            "Content-type": "application/json",
            "Authorization": authToken(),
        },
        body: JSON.stringify(poll),
    })
    return response.json();
}

export async function deletePoll(pollId:string) {
    await fetchData("https://nmic.testu.online/api/poll/"+ pollId, 
        { 
            method: "DELETE",
            headers: {
                "Authorization": authToken(),
            },
        });
}
